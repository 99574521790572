import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {pristineConfirmDialogComponent} from "./confirm-dialog.component";
import {FlexModule} from "@angular/flex-layout";

@NgModule({
  declarations: [
    pristineConfirmDialogComponent
  ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        FlexModule
    ],
  entryComponents: [
    pristineConfirmDialogComponent
  ],
})
export class pristineConfirmDialogModule {
}
