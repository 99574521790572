import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'pristine-confirm-dialog-input',
  templateUrl: './confirm-dialog-role_type.component.html',
  styleUrls: ['./confirm-dialog-role_type.component.scss']
})
export class ConfirmDialogRole_typeComponent {
  public confirmMessage: string;
  public inputFieldMessage: string;
  public  inputFieldMessage_value:string;

  public inputemailMessage: string;
  public addbutton: string = 'Add';

  public  dropdown_list:Array<string>=[];
  public  selected_dropdown:string;

  /**
   * Constructor
   *
   * @param {MatDialogRef<PristineConfirmDialogComponent>} dialogRef
   */
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogRole_typeComponent>
  ) {
  }

}
