export const loadingmessage: string[] = [
  "I'm not lazy, I'm just relaxed!!",
  "Snake? SNAAAAAAAAKE!!!",
  "The Funnies",
  "Reticulating splines...",
  "Generating witty dialog...",
  "Swapping time and space...",
  "Spinning violently around the y-axis...",
  "Tokenizing real life...",
  "Bending the spoon...",
  "Filtering morale...",
  "Please, hold your breath and start praying.",
  "Don't think of purple hippos...",
  "You're the most lovely person on earth.",
  "Contemplating the meaning of life",
  "The longer it takes me, the better it will be for you",
  "Searching for an external observer required to conduct Turing Test ...",
  "Hey, why did you click this button? You woke me up!!!",
  "Let me think about this...",
  "Please try to click a little softer, that mouse pointer is pointed!",
  "Gotcha, boss! Going right at it!",
  "Will do. In the meantime, can you prepare me a cup of tea?",
  "Please wait a moment, deciphering your intentions...",
  "Teaching Snakes to Kick", "Computer shutting down in ...",
  "Please wait whilst your harddisk is erased ...",
  "How did I get sucked in to writing out silly loading lines?",
  "Your life has meaning.",
  "What is your purpose in life?",
  "Please wait while the system allocates resources to show the loading screen..",
  "We need a new fuse...",
  "Sorry, but our princess is in another castle!",
  "Have a good day.",
  "Please wait... Rebooting the Internet",
  "Upgrading Windows, your PC will restart several times. Sit back and relax.",
  "640K ought to be enough for anybody",
  "The architects are still drafting",
  "The bits are breeding",
  "We're building the buildings as fast as we can",
  "Would you prefer chicken, steak, or tofu?",
  "(Pay no attention to the man behind the curtain)",
  "...and enjoy the elevator music...",
  "Please wait while the little elves draw your map",
  "Don't worry - a few bits tried to escape, but we caught them",
  "Would you like fries with that?",
  "Checking the gravitational constant in your locale...",
  "Go ahead -- hold your breath!",
  "...at least you're not on hold...",
  "Hum something loud while others stare",
  "You're not in Kansas any more",
  "The server is powered by a lemon and two electrodes.",
  "Please wait while a larger software vendor in India takes over the world",
  "We're testing your patience",
  "As if you had any other choice",
  "Follow the white rabbit",
  "Why don't you order a sandwich?",
  "While the satellite moves into position",
  "keep calm and npm install",
  "The bits are flowing slowly today",
  "Dig on the 'X' for buried treasure... ARRR!",
  "It's still faster than you could draw it",
  "The last time I tried this the monkey didn't survive. Let's hope it works better this time.",
  "I should have had a V8 this morning.",
  "My other loading screen is much faster.",
  "Testing on Timmy... We're going to need another Timmy.",
  "Reconfoobling energymotron...",
  "(Insert quarter)",
  "Are we there yet?",
  "Have you lost weight?",
  "Just count to 10",
  "Why so serious?",
  "It's not you. It's me.",
  "Counting backwards from Infinity",
  "Don't panic...",
  "Embiggening Prototypes",
  "Do not run! We are your friends!",
  "Do you come here often?",
  "Warning: Don't set yourself on fire.",
  "We're making you a cookie.",
  "Creating time-loop inversion field",
  "Spinning the wheel of fortune...",
  "Loading the enchanted bunny...",
  "Computing chance of success",
  "I'm sorry Dave, I can't do that.",
  "Looking for exact change",
  "All your web browser are belong to us",
  "All I really need is a kilobit.",
  "I feel like im supposed to be loading something. . .",
  "What do you call 8 Hobbits? A Hobbyte.",
  "Should have used a compiled language...",
  "Is this Windows?",
  "Adjusting flux capacitor...",
  "Please wait until the sloth starts moving.",
  "Don't break your screen yet!",
  "I swear it's almost done.",
  "Let's take a mindfulness minute...",
  "Unicorns are at the end of this road, I promise.",
  "Listening for the sound of one hand clapping...",
  "Keeping all the 1's and removing all the 0's...",
  "Putting the icing on the cake. The cake is not a lie...",
  "Cleaning off the cobwebs...",
  "Making sure all the i's have dots...",
  "We are not liable for any broken screens as a result of waiting.",
  "We need more dilithium crystals",
  "Where did all the internets go",
  "Connecting Neurotoxin Storage Tank...",
  "Granting wishes...",
  "exercise makes you smarter?",
  "Time flies when you’re having fun.",
  "Get some coffee and come back in ten minutes..",
  "Spinning the hamster…",
  "99 bottles of beer on the wall..",
  "Stay awhile and listen..",
  "Be careful not to step in the git-gui",
  "You shall not pass! yet..",
  "Load it and they will come",
  "Convincing AI not to turn evil..",
  "There is no spoon. Because we are not done loading it",
  "Your left thumb points to the right and your right thumb points to the left.",
  "How did you get here?",
  "Wait, do you smell something burning?",
  "Computing the secret to life, the universe, and everything.",
  "When nothing is going right, go left!!...",
  "I love my job only when I'm on vacation...",
  "Never steal. The government hates competition....",
  "Why are they called apartments if they are all stuck together?",
  "Life is Short – Talk Fast!!!!",
  "Optimism – is a lack of information.....",
  "Save water and shower together",
  "Whenever I find the key to success, someone changes the lock.",
  "Sometimes I think war is God’s way of teaching us geography.",
  "I’ve got problem for your solution…..",
  "Where there’s a will, there’s a relative.",
  "User: the word computer professionals use when they mean !!idiot!!",
  "Adults are just kids with money.",
  "I think I am, therefore, I am. I think.",
  "A kiss is like a fight, with mouths.",
  "You don’t pay taxes—they take taxes.",
  "Coffee, Chocolate, Men. The richer the better!",
  "I am free of all prejudices. I hate everyone equally.",
  "git happens",
  "May the forks be with you",
  "A commit a day keeps the mobs away",
  "This is not a joke, it's a commit.",
  "Constructing additional pylons...",
  "Roping some seaturtles...",
  "Locating Jebediah Kerman...",
  "We are not liable for any broken screens as a result of waiting.",
  "Hello IT, have you tried turning it off and on again?",
  "If you type Google into Google you can break the internet",
  "Well, this is embarrassing.",
  "What is the airspeed velocity of an unladen swallow?",
  "Hello, IT... Have you tried forcing an unexpected reboot?",
  "They just toss us away like yesterday's jam.",
  "They're fairly regular, the beatings, yes. I'd say we're on a bi-weekly beating.",
  "The Elders of the Internet would never stand for it.",
  "Space is invisible mind dust, and stars are but wishes.",
  "Didn't know paint dried so quickly.",
  "Everything sounds the same",
  "I'm going to walk the dog",
  "I didn't choose the engineering life. The engineering life chose me.",
  "Dividing by zero...",
  "Spawn more Overlord!",
  "If I’m not back in five minutes, just wait longer.",
  "Some days, you just can’t get rid of a bug!",
  "We’re going to need a bigger boat.",
  "Chuck Norris never git push. The repo pulls before.",
  "Web developers do it with 'STYLE'",
  "I need to git pull --my-life-together",
  "Java developers never RIP. They just get Garbage Collected.",
  "Cracking military-grade encryption...",
  "Simulating traveling salesman...",
  "Proving P=NP...",
  "Entangling superstrings...",
  "Twiddling thumbs...",
  "Searching for plot device...",
  "Trying to sort in O(n)...",
  "Laughing at your pictures-i mean, loading...",
  "Sending data to NS-i mean, our servers.",
  "Looking for sense of humour, please hold on.",
  "Please wait while the intern refills his coffee.",
  "A different error message? Finally, some progress!",
  "Hold on while we wrap up our git together...sorry",
  "Please hold on as we reheat our coffee",
  "Kindly hold on as we convert this bug to a feature...",
  "Kindly hold on as our intern quits vim...",
  "Winter is coming...",
  "Installing dependencies",
  "Switching to the latest JS framework...",
  "Distracted by cat gifs",
  "Finding someone to hold my beer",
  "BRB, working on my side project",
  "@ Insert witty loading message",
  "Let's hope it's worth the wait",
  "Aw, snap! Not..",
  "Ordering 1s and 0s...",
  "Updating dependencies...",
  "Whatever you do, don't look behind you...",
  "Please wait... Consulting the manual...",
  "It is dark. You're likely to be eaten by a grue.",
  "Loading funny message...",
  "It's 10:00pm. Do you know where your children are?",
  "Waiting Daenerys say all her titles...",
  "Feel free to spin in your chair",
  "What the what?",
  "format C: ...",
  "Forget you saw that password I just typed into the IM ...",
  "What's under there?",
  "Your computer has a virus, its name is Windows!",
  "Go ahead, hold your breath and do an ironman plank till loading complete",
  "Bored of slow loading spinner, buy more RAM!",
  "Help, I'm trapped in a loader!",
  "What is the difference btwn a hippo and a zippo? One is really heavy, the other is a little lighter",
  "Please wait, while we purge the Decepticons for you. Yes, You can thanks us later!",
  "Chuck Norris once urinated in a semi truck's gas tank as a joke....that truck is now known as Optimus Prime.",
  "Chuck Norris doesn’t wear a watch. HE decides what time it is.",
  "Mining some bitcoins...",
  "Downloading more RAM..",
  "Updating to Windows Vista...",
  "Deleting System32 folder",
  "Hiding all ;'s in your code",
  "Alt-F4 speeds things up.",
  "Initializing the initializer...",
  "When was the last time you dusted around here?",
  "Optimizing the optimizer...",
  "Last call for the data bus! All aboard!",
  "Running swag sticker detection...",
  "When nothing is going right, go left!",
  "Never let a computer know you're in a hurry.",
  "A computer will do what you tell it to do, but that may be much different from what you had in mind.",
  "Some things man was never meant to know. For everything else, there's Google.",
  "Unix is user-friendly. It's just very selective about who its friends are.",
  "Shovelling coal into the server",
  "Pushing pixels...",
  "How about this weather, eh?",
  "Building a wall...",
  "Everything in this universe is either a potato or not a potato",
  "The severity of your issue is always lower than you expected.",
  "Updating Updater...",
  "Downloading Downloader...",
  "Debugging Debugger...",
  "Reading Terms and Conditions for you.",
  "Digested cookies being baked again.",
  "Live long and prosper.",
  "There is no cow level, but there's a goat one!",
  "Deleting all your hidden porn...",
  "Running with scissors...",
  "Definitely not a virus...",
  "You may call me Steve.",
  "All you had to do is to commit the damn funnies, CJ!",
  "You seem like a nice person...",
  "Coffee at my place, tommorow at 10A.M. - don't be late!",
  "Work, work...",
  "Patience! This is difficult, you know...",
  "Discovering new ways of making you wait...",
  "Your time is very important to us. Please wait while we ignore you...",
  "Time flies like an arrow; fruit flies like a banana",
  "Two men walked into a bar; the third ducked...",
  "Sooooo... Have you seen my vacation photos yet?",
  "Sorry we are busy catching em' all, we're done soon",
  ": Insert elevator music",
  "Still faster than Windows update",
  "Composer hack: Waiting for reqs to be fetched is less frustrating if you add -vvv to your command.",
  "Obfuscating quantum entaglement",
  "Knock, Knock! Who’s there? Ken Ken who? Ken I come in? It's cold out here.",
  "If at first you don't succeed, call it version 1.0.",
  "I love pressing F5. It's so refreshing.",
  "CAPS LOCK – Preventing Login Since 1980.",
  "Chuck Norris doesn't scroll with a mouse. He uses a lion.",
  "Chuck Norris can delete the Recycling Bin.",
  "With just two toothpicks, a lightbulb, and his RoundHouse Kick, Chuck Norris can override the Pentagon's computer system.",
  "If you see the Blue Screen of Death on your laptop... it's because Chuck Norris found out you were reading Chuck Norris jokes.",
  "Chuck Norris didn't survive the first night in Minecraft, the first night survived Chuck Norris.",
  "Chuck Norris could play cd-based games on his Nintendo 64.",
  "Why did the computer get cold? Because it forgot to close windows.",
  "Chuck Norris's keyboard doesn't have a Ctrl key because nothing controls Chuck Norris.",
  "Preventing robot uprising ...",
  "Hacking into the Main Frame...",
  "Installing virus...",
  "Loading Error...",
  "PC LOAD LETTER...",
  "Waiting for something to load sucks...",
  "Deleting system32...",
  "- What are you working? - I'm a programmer. - More precisely? - At home, I change TV programs.",
  "New LG Refrigerators with Windows 10 ... 'A cold brandy pleats!' - Server Error 404, no Brandy available.",
  "Oh, are you waiting for me?",
  "Do you smell carrots? -a snowman",
  "That job was a piece of cake",
  "Oh you are an eager beaver",
  "He makes a monkey business",
  "Next year the government will start deporting all of the weird old people.",
  "I started crying when I thought of you.",
  "Run, my friend, RUN !!!",
  "PC LOAD LETTER. please wait.",
  "CPU on fire? please wait.",
  "Photophlow has some amusing loading messages. Check it out.",
  "ordering XYZ book from Amazon...",
  "checking your collection",
  "checking your assets",
  "The world is coming to an end. Please log off.",
  "I was trying to think of Flight of the Concords lyrics (I love that show too):",
  "Quit Edge and use Firefox!",
  "5secs more.. Still you are here.. Time to change your service provider.",
  "5secs more.. When did you upgrade your hardware last time?",
  "5secs more.. Oh boy, you must be using the OS which was built a decade ago. Try Mac.",
  "5secs more.. I have stopped loading, pls navigate to a site which is compatible with your speed!",
  "Actually Loading... Please wait.",
  "Listening to the sound of the human nature",
  "Winding up the rubber band...",
  "Trapped in Loading... factory, send help.",
  "Coffee time!",
  "Minesweeper time!",
  "User patience ending...",
  "Burning your belly fat...",
  "Connecting to NSA main server...",
  "Making fun of you...",
  "Feature unavalaible. Try again in 30 minutes. Kidding.  Wait don't close it",
  "If you refesh the window. I will kill you.",
  "Find your soul, Sorry 404 Not Found",
  "Downloading the Internet... ERROR: puny disk detected.",
  "Scanning the Internet for virus...",
  "Watching TV...",
  "Scanning Mars for life signals...",
  "Adapting the application to your heart frequency",
  "We know you like these, we're sorry they don't last enough.",
  "Did you know we make you wait so you can see the ads?",
  "Remember to take some food with you.",
  "I'll be right back.",
  "Sorry for the bad smell.",
  "Look behind you!!",
  "Totally loaded. (I am, you are, but NOT this application...)",
  "Please wait while scanning your hard drive for movies",
  "What's the square root of 256?",
  "How many bytes is a petabyte?",
  "Do you know where the flies sleep?",
  "Suggest putting time until completion to good use. Maybe learn to play the violin?",
  "Loading times too long? Karma for something you've done bad",
  "Someone put some money in the meter, it's stopped again",
  "Are you still sure you wanted that feature instead of performance?",
  "Loading ... go potty before you go potty",
  "Loading ... haven't you anything better to do than to read absurdly long and boring messages",
  "Loading ... do you like cricket",
  "Loading ... the information superhighway appears to be gridlocked",
  "Loading ... time for a coffee",
  "Wait wait wait. I'm meant to DO something?!",
  "Stop. Hammertime.",
  "And now it's time for a breakdown.",
  "Setting us up the bomb. What you say?",
  "Please wait a while.. We are going to make you an offer that you can't refuse",
  "You can also put a microgame (< minigame) in the loading screen, if it's long...",
  "Calculating time to load site...",
  "Wouldn't it be nice if there were an Escape key for all of our problems?",
  "Loading... Please be patie... OMG! Your dog is on fire!",
  "Reassembling atoms...",
  "Unpacking data package...",
  "Setting the modulating fibulator to full polarity...",
  "Keep smiling , we found your smiley face cute :)",
  "Is that the time already?",
  "Sometime your agony will be over ..",
  "Put the kettle on. It's going to take a minute.",
  "I would like to program a loading screen that makes you believe that you have a killer behind you. Should of course use the webcam.",
  "Still there?",
  "Do you think this page will load?",
  "Man, I need to pee",
  "Waking up your lazy processor.",
  "Run For Your Life",
  "Calculating meaning of life....",
  "You are dead, I told your wife about the trip!",
]

export const loadingstyle: string[] = [
  "pacman",
  "ball-8bits",
  "ball-atom",
  "ball-beat",
  "ball-circus",
  "ball-climbing-dot",
  "ball-clip-rotate",
  "ball-clip-rotate-multiple",
  "ball-clip-rotate-pulse",
  "ball-elastic-dots",
  "ball-fall",
  "ball-fussion",
  "ball-grid-beat",
  "ball-grid-pulse",
  "ball-newton-cradle",
  "ball-pulse",
  "ball-pulse-rise",
  "ball-pulse-sync",
  "ball-rotate",
  "ball-running-dots",
  "ball-scale",
  "ball-scale-multiple",
  "ball-scale-pulse",
  "ball-scale-ripple",
  "ball-scale-ripple-multiple",
  "ball-spin",
  "ball-spin-clockwise",
  "ball-spin-clockwise-fade",
  "ball-spin-clockwise-fade-rotating",
  "ball-spin-fade",
  "ball-spin-fade-rotating",
  "ball-spin-rotate",
  "ball-square-clockwise-spin",
  "ball-square-spin",
  "ball-triangle-path",
  "ball-zig-zag",
  "ball-zig-zag-deflect",
  "cog",
  "cube-transition",
  "fire",
  "line-scale",
  "line-scale-party",
  "line-scale-pulse-out",
  "line-scale-pulse-out-rapid",
  "line-spin-clockwise-fade",
  "line-spin-clockwise-fade-rotating",
  "line-spin-fade",
  "line-spin-fade-rotating",
  "square-jelly-box",
  "square-loader",
  "square-spin",
  "timer",
  "triangle-skew-spin"
]
