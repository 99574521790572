import { Injectable } from '@angular/core';
import {SessionManageMent} from "./SessionManageMent";
import {MatDialog} from "@angular/material/dialog";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {WebApiHttp} from "./WebApiHttp.services";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class ErrorCopyService {

  constructor(private sessionManageMent: SessionManageMent,
              private _dig: MatDialog,
              private spinner: NgxSpinnerService,
              private pristineToaster: ToastrService,
              private webApiHttp: WebApiHttp,
              private _snackBar: MatSnackBar) { }


  PostMethod(url,json,res,time) {
    let message = ''
    let action = 'Copy Request'
    let snackBarRef = this._snackBar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['snackbar-container'],
    });
    snackBarRef.onAction().subscribe(() => {
      this.copyPostMethod(url,json,res,time)
    });
  }


  copyPostMethod(url,json,response,time) {
    const jsonString = JSON.stringify(json).replace(/\"/g, '\\"');
    console.log(jsonString)
    const textToCopy = `URL :  ${this.webApiHttp.globalurl+url}
Method Type : POST
Payload : ${JSON.stringify(json)}
Response On : ${time}
Response : ${JSON.stringify(response)}
CURL REQUEST :  curl -X POST "${this.webApiHttp.globalurl+url}" -H  "accept: /" -H  "Content-Type: application/json" -d "${jsonString}`;

    let textarea = document.getElementById('copyTextarea') as HTMLTextAreaElement;
    if (!textarea) {
      textarea = document.createElement('textarea');
      textarea.setAttribute('id', 'copyTextarea');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
    }
    textarea.value = textToCopy;
    textarea.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.pristineToaster.success('Copied Successfully');
  }

  GetMethod(ApiUrl,result,time)
  {
    let message = ''
    let action = 'Copy Request'
    let snackBarRef = this._snackBar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['snackbar-container'],
    });
    snackBarRef.onAction().subscribe(() => {
      this.CopyGetMethod(ApiUrl,result,time)
    });
  }

  CopyGetMethod(ApiUrl,result,time)
  {
    const textToCopy = `URL :  ${this.webApiHttp.globalurl+ApiUrl}
Method Type : GET
Response On : ${time}
Response : ${JSON.stringify(result)}
CURL REQUEST :  curl -X GET "${this.webApiHttp.globalurl+ApiUrl}" -H  "accept: /" -H  "Content-Type: application/json" -d "`;

    let textarea = document.getElementById('copyTextarea') as HTMLTextAreaElement;
    if (!textarea) {
      textarea = document.createElement('textarea');
      textarea.setAttribute('id', 'copyTextarea');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
    }
    textarea.value = textToCopy;
    textarea.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.pristineToaster.success('Copied Successfully');
  }

  GetParamsMethod(ApiUrl,paramsUrl,res,time)
  {
    let message = ''
    let action = 'Copy Request'
    let snackBarRef = this._snackBar.open(message, action, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['snackbar-container'],
    });
    snackBarRef.onAction().subscribe(() => {
      this.CopyGetParamsMethod(ApiUrl,paramsUrl,res,time)
    });
  }

  CopyGetParamsMethod(ApiUrl,paramsUrl,res,time)
  {
      const textToCopy = `URL :  ${this.webApiHttp.globalurl+ApiUrl}
Method Type : GET
Response On : ${time}
Response : ${JSON.stringify(res)}
CURL REQUEST :  curl -X GET "${this.webApiHttp.globalurl+paramsUrl}" -H  "accept: /" -H  "Content-Type: application/json" -d "`;

      let textarea = document.getElementById('copyTextarea') as HTMLTextAreaElement;
      if (!textarea) {
        textarea = document.createElement('textarea');
        textarea.setAttribute('id', 'copyTextarea');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';
        document.body.appendChild(textarea);
      }
      textarea.value = textToCopy;
      textarea.select();
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      this.pristineToaster.success('Copied Successfully');
  }

}
