<div [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}" class="nav">

  <!-- Vertical Navigation Layout -->
  <ng-container *ngIf="layout === 'vertical'">

    <ng-container *ngFor="let item of navigation">

      <pristine-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></pristine-nav-vertical-group>
      <pristine-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
                                         [item]="item"></pristine-nav-vertical-collapsable>
      <pristine-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></pristine-nav-vertical-item>

    </ng-container>

  </ng-container>
  <!-- / Vertical Navigation Layout -->

  <!-- Horizontal Navigation Layout -->
  <!--    <ng-container *ngIf="layout === 'horizontal'">-->

  <!--        <ng-container *ngFor="let item of navigation">-->

  <!--            <pristine-nav-horizontal-collapsable *ngIf="item.type=='group'" [item]="item"></pristine-nav-horizontal-collapsable>-->
  <!--            <pristine-nav-horizontal-collapsable *ngIf="item.type=='collapsable'"-->
  <!--                                             [item]="item"></pristine-nav-horizontal-collapsable>-->
  <!--            <pristine-nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></pristine-nav-horizontal-item>-->

  <!--        </ng-container>-->

  <!--    </ng-container>-->
  <!-- / Horizontal Navigation Layout -->

</div>
