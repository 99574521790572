import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {FlexLayoutModule} from "@angular/flex-layout";
import {
  ConfirmDialogRole_typeComponent
} from "./confirm-dialog-role_type.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    ConfirmDialogRole_typeComponent
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSelectModule,
    CommonModule
  ],
  entryComponents: [
    ConfirmDialogRole_typeComponent
  ],
})
export class ConfirmDialogRole_typeModule {
}
