<mat-toolbar class="p-0 mat-elevation-z1">

  <div fxFill fxFlex fxLayout="row" fxLayoutAlign="start center">

    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

      <button (click)="toggleSidebarOpen('navbar')" *ngIf="!hiddenNavbar && !rightNavbar"
              class="navbar-toggle-button" fxHide.gt-md mat-icon-button>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div *ngIf="!hiddenNavbar && !rightNavbar" class="toolbar-separator" fxHide.gt-md></div>

      <div *ngIf="horizontalNavbar" fxLayout="row" fxLayoutAlign="start center">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/logos/mylogo.png">
        </div>
      </div>

      <div class="px-8 px-md-16">
        <!--                                <pristine-shortcuts [navigation]="navigation"></pristine-shortcuts>-->
      </div>
    </div>
    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

      <button mat-icon-button [matBadge]="downloadedCount" matBadgePosition="after"
              [matBadgeHidden]="downloadedCount<=0"
              matBadgeColor="warn" (click)="downloadingFunction()" *ngIf="downList.length>0">
        <img src="assets/icons/file_download.svg" alt="Download" class="mr-8"
             [matMenuTriggerFor]="downloading"/>
      </button>
      <button [matMenuTriggerFor]="notification" mat-icon-button [matBadge]="pendingAcknowledgement>0?pendingAcknowledgement:''" [matBadgeColor]="'warn'"><mat-icon>notifications</mat-icon></button>
      <mat-menu #notification="matMenu" xPosition="before" class="w-400  notification_Menu" (click)="$event.stopPropagation()">
        <span class="h3 text-bold px-12 mb-12" style="position: relative; top: 0; width: 100% ">Notification</span>
        <mat-divider class="mt-12"></mat-divider>
        <div class="BoxSize cursor-default"  (click)="$event.stopPropagation()">
          <div fxLayout="column" [style.background]="line.acknowledgement>0?'white':''" fxLayoutAlign="start stretch"
               [ngClass]="ind<(notifications.length-1)?line.acknowledgement>0?['p-12' ,'bb','hover']:['p-12' ,'bb','hov']:line.acknowledgement>0?['p-12' ,'hover']:['p-12' ,'hov']"
               [matTooltip]="line.acknowledgement<1?'Mark as read':null" matTooltipShowDelay="500"
               (click)="line.acknowledgement<1?markRead(line.id):null"
               *ngFor="let line of notifications; let ind=index">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="secondary-text mat-h5 m-0">
              <span>{{line?.created_by}}</span>
              <span>{{line?.created_on| date:'dd MMM yy, hh:mm a'}}</span>
            </div>
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center">
              <mat-icon [style.color]="colorArray[ind%6]">mail</mat-icon>
              <span>{{line?.message}}</span>
              <mat-icon  class="cursor-pointer" [color]="line.acknowledgement>0?'accent':'primary'" >done_all</mat-icon>
            </div>
          </div>
        </div>
        <span fxLayoutAlign="center center" fxFlex class="p-12 text-center h3 m-0 secondary-text" style="background: white" *ngIf="notifications.length<1">No Records Found</span>
      </mat-menu>
      <button [matMenuTriggerFor]="user_profile" class="user-button"
               mat-button>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="center center">
            <!--                    <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg">-->
            <mat-icon>account_circle</mat-icon>&nbsp;
            <span class="username mr-12" fxShow.gt-sm>{{_sessionManagement.getName}}</span>
            <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
          </div>
          <span fxShow.gt-sm fxHide  *ngIf="_sessionManagement.getIsHo=='1'" style="font-size: 0.6em; padding-top:.8em ">({{_sessionManagement.getLocationId}})</span>
        </div>
      </button>
      <mat-menu #user_profile="matMenu" class="w-300">

        <div fxLayout="column" class="pl-8">
          <img class="avatar mr-0 mr-sm-24 my_user_loginSection" src="assets/images/user_profile.png">

          <div fxLayout="row" id="user_loginSection" fxLayoutAlign="space-between center">
            <b>{{_sessionManagement.getName}}</b>
            <mat-icon>keyboard_arrow_right</mat-icon>
          </div>
        </div>
        <mat-divider></mat-divider>

        <mat-nav-list class="myCustomeclassFoeUserLoginSection">

          <mat-list-item>
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center" (click)="$event.stopPropagation()">
              <mat-icon mat-list-icon class="mr-8 secondary-text">
                location_on
              </mat-icon>

              <p *ngIf="_sessionManagement.getIsHo=='0'" matLine fxFlex>{{_sessionManagement.getLocationId}}</p>
              <mat-form-field *ngIf="_sessionManagement.getIsHo=='1'"
                 class="w-220 mt-8" >
                <mat-label>Select Location</mat-label>
                <mat-select [(value)]="location">
                  <input #searchloc
                         autocomplete="off"
                         class="myInput"
                         focused="'true'" matInput placeholder="Search"
                         type="text">
                  <mat-option (click)="selLocation(loc);"
                              *ngFor="let loc of locationModel| search_by_key:'location_name':searchloc.value"
                              [value]="loc.location_id">{{loc.location_name}} ( {{loc.location_id}} )</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

          </mat-list-item>

          <mat-list-item [routerLink]="'/apps/chat'">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon mat-list-icon class="mr-8 secondary-text">
                email
              </mat-icon>
              <p matLine fxFlex>{{_sessionManagement.getEmail}}</p>
              <!--                            <mat-icon class="ml-8 amber-fg">keyboard_arrow_right</mat-icon>-->
            </div>

          </mat-list-item>
<!--          <mat-list-item *ngIf="_sessionManagement.getIsHo<=0">-->
<!--            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">-->
<!--              <mat-icon mat-list-icon class="mr-8 secondary-text">-->
<!--                location_on-->
<!--              </mat-icon>-->
<!--              <p matLine fxFlex>-->
<!--                {{_sessionManagement.getLocationName}}  ( {{_sessionManagement.getLocationId}} )-->
<!--              </p>-->
<!--              &lt;!&ndash;                            <mat-icon class="ml-8 amber-fg">keyboard_arrow_right</mat-icon>&ndash;&gt;-->
<!--            </div>-->

<!--          </mat-list-item>-->
          <mat-list-item *ngIf="_sessionManagement.getIsHo>0">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon mat-list-icon class="mr-8 secondary-text">
                location_on
              </mat-icon>
              <p matLine fxFlex>
                {{_sessionManagement.getClusterSession}}
              </p>
              <!--                            <mat-icon class="ml-8 amber-fg">keyboard_arrow_right</mat-icon>-->
            </div>

          </mat-list-item>
          <mat-list-item>
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon mat-list-icon class="mr-8 secondary-text">
                streetview
              </mat-icon>
              <p matLine fxFlex>{{_sessionManagement.getRoleName}}</p>
              <!--                            <mat-icon class="ml-8 amber-fg">keyboard_arrow_right</mat-icon>-->
            </div>

          </mat-list-item>
          <mat-list-item>
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon mat-list-icon class="mr-8 secondary-text">
                smartphone
              </mat-icon>
              <p matLine fxFlex>{{_sessionManagement.getdevice_type}}</p>
              <!--                            <mat-icon class="ml-8 amber-fg">keyboard_arrow_right</mat-icon>-->
            </div>

          </mat-list-item>

        </mat-nav-list>
        <div fxLayout="column" >
          <mat-divider></mat-divider>
          <div (click)="LogoutFrom_Server()" matRipple class="logout_section" fxLayout="row"
               fxLayoutAlign="start center">
            <mat-icon mat-list-icon class="mr-8 secondary-text">
              exit_to_app
            </mat-icon>
            <p matLine fxFlex>Logout</p>
          </div>
        </div>
      </mat-menu>

      <mat-menu #downloading="matMenu" class="w-400" >
        <div fxFlex fxLayout="column" fxLayoutGap="4px"  (click)="$event.stopPropagation();$event.preventDefault()">
          <div fxLayout="column"  class="bb bt hover px-12 ml-8" style="border-bottom-left-radius: 30px;
           border-top-left-radius: 30px; overflow:hidden"  *ngFor="let ele of downList; let i = index">
            <div fxFlex  fxLayoutAlign="space-between center" fxLayout="row" fxLayoutGap="1rem" class="py-4">
              <mat-icon>description</mat-icon>
              <span fxFlex="75" class="py-4 pb-0" fxLayout="row" fxLayoutAlign="space-between center">
                <p class="p-0 h3 m-0 h3">{{ele?.name}}</p>
              </span>
              <span fxLayout="row" fxLayoutGap="4px">
                <mat-icon (mouseover)="dwn.color='accent'" [matTooltipDisabled]="ele?.fileDownload" class="cursor-pointer mx-0 text-center" *ngIf="!ele['downloadProgress'] && ele?.message?.length ==''"

                          (mouseleave)="dwn.color='primary'" matTooltip="Download"  (click)="!ele?.fileDownload?startDownload(ele?.id, i):''"
                          #dwn>cloud_download

                </mat-icon>
                <mat-icon *ngIf="!ele['downloadProgress'] && ele?.message?.length =='' && ele?.csv"
                          (mouseenter)="cpy.color='accent';"
                          (mouseleave)="cpy.color='primary';"
                          color="primary"
                          class="cursor-pointer mx-0 ml-4"
                          (click)="copyTxt(i,tooltip, true)"
                          matTooltip="Copy Link" #tooltip="matTooltip" #cpy>link</mat-icon>
                <mat-icon (mouseover)="clr.color='warn'"

                          (mouseleave)="clr.color='primary'" matTooltip="Remove" class="cursor-pointer mx-0 "  (click)="deleteLine(ele?.id, i)" #clr>

                             {{ele['downloadProgress']?'clear':'delete'}}
                 </mat-icon>
              </span>
            </div>

            <!--            <span fxLayoutAlign="end end">-->
            <!--            </span>-->

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span fxFlex="80" class="ml-28">
                <p style="color: #b30000; font-size: 0.6em; word-break: break-all; word-wrap: break-spaces" *ngIf="ele.message != ''" class="m-0 px-12" >{{ele?.message}}</p>
                <p style="color: #999999; font-size: 0.8em;" *ngIf="ele.message == ''" class="m-0 px-12" >{{ele?.status}}</p>
              </span>
              <span fxFlex="15">
                <p class="m-0 h6" style=" font-size: 0.6em;">{{ele?.id | date: 'hh:mm dd MMM'}}</p>
              </span>
            </div>
            <mat-progress-bar class="border-radius-8" *ngIf="ele?.downloadProgress" mode="indeterminate"></mat-progress-bar>

            <!--            <mat-progress-bar class="border-radius-8"-->
            <!--                              *ngIf="ele?.fileDownload" mode="determinate"-->
            <!--                              [value]="ele?.gain/ele?.fileSize"-->
            <!--                              [bufferValue]="(ele?.gain+(ele?.gain/3))/ele?.fileSize"-->
            <!--                              color="warn"-->
            <!--            ></mat-progress-bar>-->

          </div>

        </div>
      </mat-menu>
      <div class="toolbar-separator"></div>
    </div>
  </div>

</mat-toolbar>
